@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

@font-face {
  font-family: "Paragon";
  src: url(./assets/paragon/Paragon-Regular.otf);
}

@font-face {
  font-family: "Helvetica Rounded";
  src: url(./assets/helvetica/helvetica-rounded-bold.woff);
}

body {
  margin: 0;
  font-family: "Paragon", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Helvetica Rounded", sans-serif;
}

button {
  font-family: "Helvetica Rounded", sans-serif;
  text-transform: lowercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p>a {
  text-decoration: underline;
  color: #5468D2;
}

ol {
  list-style-type: decimal;
  padding: 0 30px;
}

ul {
  list-style-type: disc;
  padding: 0 30px;
}

.ql-size-small {
  font-size: 0.75em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}
.ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}

.dropdown-menu {
  max-height: 146px;
  overflow: scroll;
  overflow-x: hidden;
  margin-top: 0px;
}

.caret {
  float: right;
  margin-top: 2%;
}

#menu1 {
  width: 160px; 
  text-align: left;
}